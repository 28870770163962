<template>
  <!-- Begin page -->
  <div id="layout-wrapper">
    <Common ref="commonFunc"/>
    <Topbar v-if="!changeTopbarView" />
    <TopbarWithBack v-else :BackLink="Link" :Color="Color" :MyLoanBalance="Balance" :MyCardLast4Digits="Card4Digits" :PageTitle="PageTitle" :ApplyLoanStep="ApplyLoanStep"/>
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content">
      <div class="page-content py-0">
        <div class="container">
          <slot />
        </div>
      </div>
      <!-- End Page-content -->
      <Footer :showFooterCert="ShowFooterCert" />
    </div>
    <!-- end main content-->
    <nav class="navbar fixed-bottom navbar-light bg-white bottom_nav">
      <div class="container text-center justify-content-center p-0">
        <router-link  v-for="(value, index) in menu" :key="index" :to="{ name: value.path_name}">
          <img :src="returnIconActive(index)" height="24">
          <span class="d-block fw-500 font-size-13">{{$t(value.title)}}</span></router-link>
      </div>
    </nav>
    <b-modal
      @hidden="resetModal"
      ref="modal-withdraw"
      centered
      hide-footer
      hide-header
      content-class="bg-primary border-primary overflow-hidden"
    >
      <div class="row align-items-center">
        <div class="col text-white" >
        <div class=" animate__animated animate__slideInLeft"><i class="mdi mt-2 mdi-wallet font-size-22"></i></div>
        <h4 class="text-white mt-0 font-size-15  animate__animated animate__slideInLeft">{{$t('siteLang.WithdrawalAmount')}}</h4>
        <h3 class="text-white animate__animated animate__slideInDown">Rp. 
          <span data-plugin="counterup">
              <countTo :startVal="500" :endVal="parseInt(Balance.replaceAll(',',''))" :duration="1000"></countTo>
          </span>
        </h3></div>
        <div class="col-auto text-end">
          <img src="@/assets/images/app/header_vector2.png" height="28" class="me-2 animate__animated animate__slideInDown"> 
          <img src="@/assets/images/app/applyloan.png" height="130" class="d-block animate__animated animate__slideInLeft">
        </div>
        <div class="col-12">
          <div class="card p-3 m-0 animate__animated animate__slideInUp" style="margin-top:-10px">
            <div class="form-group mb-3">
              <label for="pin" class="form-label font-size-15">{{$t("siteLang.VerificationCode")}}</label>
              <input type="tel" class="form-control form-control-lg" maxlength="6" id="pin" placeholder="Verification Code" v-model="secretPin" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');"
              :class="{
                'is-invalid': submitted && $v.secretPin.$error,
              }">
              <div
                v-if="submitted && $v.secretPin.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.secretPin.required"
                  >{{$t("siteLang.VerificationCode")}} {{$t('siteLang.isRequired')}}</span
                >
              </div>
            </div>
            <div class="form-group mb-3">
              <label for="acc_number" class="form-label font-size-15">{{$t("siteLang.AccountNumber")}}</label>
              <input type="text" class="form-control form-control-lg" disabled id="acc_number" placeholder="********" v-model="ShowCard4Digits">
            </div>
            <div class="form-group d-flex">
              <button type="button" class="btn btn-secondary me-1 btn-lg w-100" @click="$refs['modal-withdraw'].hide()"><i class="mdi mdi-close"></i> {{$t("siteLang.Cancel")}}</button>
              <button type="button" class="btn btn-primary ms-1 btn-lg w-100" @click="ProcessWithdrawal" :disabled="loading">
                <span v-if="loading">{{$t("siteLang.Withdrawing")}}...</span>
                <span v-else>{{$t("siteLang.Withdraw")}} <i class="mdi mdi-cash-multiple"></i></span>
                <span class="spinner-border spinner-border-sm ms-2" v-if="loading" role="status"
                  aria-hidden="true"></span>  
              </button>
              
            </div>
          </div>
        </div>
        
      </div>
    </b-modal>
  </div>
</template>
<script>
import router from "@/router";
import { layoutComputed } from "@/state/helpers";
import axios from "axios";
import Swal from "sweetalert2";
import countTo from "vue-count-to";
import Topbar from "@/components/topbar";
import TopbarWithBack from "@/components/topbarWithBack";
//import Sidebar from "@/components/side-bar";
//import RightSidebar from "@/components/right-sidebar";
import Footer from "@/components/footer";
import { required} from "vuelidate/lib/validators";
import Common from "@/components/common";
import appConfig from "@/app.config";


export default {
  data() {
    return {
       changeTopbarView:false,
       isMenuCondensed: false,
       menu: [
        {
          icon: require("@/assets/images/app/home.png"),
          icon_active: require("@/assets/images/app/home2.png"),
          path_name: "home",
          title: 'siteLang.Home',
        },
        {
          icon: require("@/assets/images/app/wallet.png"),
          icon_active: require("@/assets/images/app/wallet2.png"),
          path_name: "bill",
          title: 'siteLang.Bill',
        },
        {
          icon: require("@/assets/images/app/support.png"),
          icon_active: require("@/assets/images/app/support2.png"),
          path_name: "support",
          title: 'siteLang.Support',
        },
        {
          icon: require("@/assets/images/app/me.png"),
          icon_active: require("@/assets/images/app/me2.png"),
          path_name: "me",
          title: 'siteLang.Me',
        },
      ],
      Color:"",
      Link:"",
      Balance:"",
      Card4Digits:"",
      ShowCard4Digits:"",
      ShowFooterCert:true,
      PageTitle:"",
      ApplyLoanStep:0,
      submitted:false,
      loading:false,
      secretPin:"",
      accessToken:'',
      accessPhone:'',
    }
  },
  validations: {
    secretPin:{
      required
    }
   
  },
  components: {
    Topbar,
    TopbarWithBack,
    //Sidebar,
    //RightSidebar,
    Footer,
    countTo,
    Common
  },
   computed: {
    ...layoutComputed,
    
  },
  name: "vertical",
  created() {
    document.body.removeAttribute("data-layout");
    document.body.removeAttribute("data-topbar");
  },
  mounted(){
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessPhone = this.$refs.commonFunc.getPhone()
  },
  methods: {
    returnIconActive(index) {
      return this.$route.name === this.menu[index].path_name ? this.menu[index].icon_active : this.menu[index].icon;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    changeTopbar(link,setBg){
      this.Link=link
      this.Color=setBg
      this.changeTopbarView = !this.changeTopbarView;
      console.log('change topbar')
    },
    updateBalance(value){
      this.Balance = value
    },
    updateCard4Digits(value){
      this.Card4Digits = value
    },
    showFooterCertFunc(value){
      this.ShowFooterCert = value
    },
    setPageTitleFunc(value){
      this.PageTitle = value
    },
    setApplyLoanStepFunc(value){
      this.ApplyLoanStep = value
    },
    WithdrawalFunc(){
      this.ShowCard4Digits = '**** **** **** ' + this.Card4Digits.toString().slice(-4) || ' - - - -'
      this.$refs['modal-withdraw'].show()
    },
    ProcessWithdrawal(){
        this.submitted = true
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone", this.accessPhone);
        bodyFormData.append("loanOtp", this.secretPin);
        
        axios({
            method: "post",
            url: appConfig.APIHost +  "controller/webapp/loanOtpVerification",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" }
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              
              Swal.fire(
                {
                icon: 'success',
                title: this.$t('siteLang.Success'),
                html: this.$t('siteLang.WithdrawalSuccessMsg'),
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
                }).then((result) => {
                console.log(result)
                this.secretPin = ""
                console.log(this.$route.query)
                const currentQuery = new URLSearchParams(this.$route.query);
                currentQuery.set('reload', '1');
                const queryObject = Object.fromEntries(currentQuery.entries());
                this.$router.replace({ query: queryObject });
    
                this.$refs['modal-withdraw'].hide()
                })
              
            }else if (resData.status == 401){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.submitted = false
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
      console.log('Withdrawal')
    },
    resetModal(){
      this.submitted = false;
      this.loading = false;
      this.secretPin = ""
    }
    
  },
  
};
</script>


<style>
#app{
  overflow-x: hidden;
}
.bottom_nav{
  box-shadow: 0 -2px 4px rgba(15, 34, 58, 0.12);
  z-index: 55;  
  overflow: hidden;
}
.authentication-bg #contentContainer{
  background-color: #04da8c !important;
}
.bottom_nav a{
  width: 25%;
  color: #A7A9AB;
}
.bottom_nav a.router-link-exact-active{
  color: #04DA8C!important ;
}
</style>