<script>
import appConfig from "@/app.config";
export default {
    
    props: {
      showFooterCert: {
        type: Boolean,
        default: true,
      }
    },
    data() {
        return {
            version: appConfig.version
        }
    },
}
</script>

<template>
    <footer class="footer position-relative start-0 border-0 h-auto mb-5 shadow-none font-size-15">
    <div class="container-fluid text-center mb-4 ">
        <div class="row justify-content-center" v-if="showFooterCert">
            <div class="col-8 text-muted text-center">
                <p class="font-size-13 mb-1">{{$t('siteLang.SupervisedRegisteredandCertifiedby')}}</p>
                <img src="@/assets/images/app/certs.png" class="mb-4 img-fluid" alt="">
            </div>
        </div>
       
        <div class="row">
            <div class="col-12 text-muted font-size-13">{{ new Date().getFullYear()}} © AppModule | {{version}}</div>
        </div>
    </div>
    </footer>
</template>