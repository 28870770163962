<script>
import {
    layoutComputed
} from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";

/**
 * Main Layout
 */
export default {
    components: {
        Vertical,
        Horizontal,
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
    },
    mounted() {
        document.body.classList.remove('authentication-bg')
    },
    methods: {
        changeVerticalTopBar(link, setColor){
            this.$refs.vertical.changeTopbar(link, setColor)
            console.log('oklah')
        },
        updateBalance(value){
            this.$refs.vertical.updateBalance(value)
            console.log('Balancelah')
        },
        updateCard4Digits(value){
            this.$refs.vertical.updateCard4Digits(value)
            console.log('updateCard4Digits')
        },
        setShowFooterCert(value){
            this.$refs.vertical.showFooterCertFunc(value)
            console.log('ShowFooterCert')
        },
        setPageTitle(value){
            this.$refs.vertical.setPageTitleFunc(value)
            console.log('setPagetitle')
        },
        setApplyLoanStep(value){
            this.$refs.vertical.setApplyLoanStepFunc(value)
            console.log('setPagetitle')
        },
    },
};
</script>

<template>
<div>
    <Vertical v-if="layoutType === 'vertical'" :layout="layoutType" ref="vertical">
        <slot />
    </Vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
        <slot />
    </Horizontal>
</div>
</template>
