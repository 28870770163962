<script>
export default {
  components: {
  },
  props: {
    BackLink: {
      type: String,
      default:"home",
      required: false
    },
    Color:{
      type: Boolean,
      default: false,
      required: false
    },
    PageTitle:{
      type: String,
      required: false
    },
    MyLoanPage:{
      type: Boolean,
      default:false,
      required: false
    },
    MyLoanBalance:{
      type: String,
      required: false
    },
    MyCardLast4Digits:{
      type: String,
      required: false
    },
    ApplyLoanStep:{
      type: Number,
      required: false,
      default:0
    },
    
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<template>
  <header>
   <div :class="{'bg-primary text-white': Color}">
   <div class="container" >
    <div class="row">
      <div class="col-12">
        <div class="d-flex py-3">
          <div class="me-auto ps-2 ps-md-0">
            <router-link :to="{ name: BackLink}" v-if="BackLink">
                <i class="mdi mdi-arrow-left font-size-24 text-dark" :class="{'text-white': Color}"></i>
            </router-link>
          </div>
          <div class="pe-2 pe-md-0">
            <img src="@/assets/images/app/header_vector2.png" v-if="Color" height="32">
            <img src="@/assets/images/app/header_vector.png" v-else height="32">
          </div>
        </div>
      </div>
    </div>
   </div>
   <div class="bg-primary text-center text-white" v-if="MyLoanBalance">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="position-relative animate__animated animate__fadeInDown ">
            <img src="@/assets/images/app/card.png" class="img-fluid" width="360px">
            <div class="position-absolute top-50 start-50 translate-middle">
              <div class="card_text_start mt-5 text-start font-size-16">
                <span class="me-2">****</span><span class="me-2">****</span><span class="me-2">****</span> {{MyCardLast4Digits.toString().slice(-4) || ' - - - -'}}</div>
              <div class="card_text_start font-size-12 text-start mt-2">
                {{$t('siteLang.Balance')}} 
                <h4 class="m-0 text-white font-size-18">Rp. {{MyLoanBalance}}</h4> 
              </div>
            </div>
            </div>
          </div>
          <div class="col-12 mb-4 animate__animated animate__fadeInUp">
            <button type="button" :disabled="!parseInt(MyLoanBalance)>0" @click="this.$parent.WithdrawalFunc" class="btn btn-custom border-white px-4 font-size-15 text-white fw-medium waves-effect btn-rounded"><i class="mdi mdi-wallet font-size-16"></i>  {{$t('siteLang.Withdrawal')}}</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!PageTitle.includes('#loan') && MyLoanBalance==''" >
      <div class="bg-primary text-start text-white py-0 " v-if="PageTitle">
        <div class="container" >
        <div class="row">
          <div class="col-12">
            <h1 class="px-md-0 px-2 text-white font-size-22 mb-3 animate__animated animate__fadeInLeft">{{PageTitle}}</h1>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
 
  <div v-if="PageTitle.includes('#loan')">
  <div :class="{'bg-primary text-white': Color}">
    <div class="bg-primary text-start text-white ">
        <div class="container position-relative">
          <div class="row">
            <div class="col-12 mb-2">
              <h1 class="px-md-0 px-2 text-white font-size-22 mb-1 animate__animated animate__fadeInLeft">{{$t('siteLang.ApplyForLoan')}}</h1>
              <p class="mb-5 px-md-0 px-2 animate__animated animate__fadeInLeft">
                {{$t('siteLang.ApplyForLoanSlogan')}}</p>
            </div>
            <img src="@/assets/images/app/applyloan.png" class="position-absolute w-auto end-0 bottom-0 appyloan_img" height="120" >
          </div>
        
        </div>
      </div>
  </div>
  <div class="container ">
    <div class="row">
      <div class="col-12 px-3 px-md-2 position-relative">
          <!-- start step indicators -->
          <div class="bg-white rounded border-0 shadow-sm floatingTop">
          <div class="step-wrap d-flex mb-0 border-bottom border-color-custom py-4 pb-3" >
              <span class="stepIndicator" :class="{'active':ApplyLoanStep>=0}"><span>1</span> {{ $t('siteLang.LoanDetail') }}</span>
              <span class="stepIndicator" :class="{'active':ApplyLoanStep>=1}"><span>2</span>{{ $t('siteLang.myInfo') }}</span>
              <span class="stepIndicator" :class="{'active':ApplyLoanStep>=2}"><span>3</span>{{ $t('siteLang.ICSignature') }}</span>
              <span class="stepIndicator" :class="{'active':ApplyLoanStep>=3}"><span>4</span>{{ $t('siteLang.Agreement') }}</span>
          </div>
          </div>
          <!-- end step indicators -->
        </div>
    </div>
  </div>
  </div>
  </header>
</template>
<style lang="scss" scoped>
.border-white{border-width: 2px;}
.btn-custom:hover{
  background-color: #fff;
  color:#04da8c !important

}
.card_text_start{
  margin-left: -40%;
  position: relative;
}
.floatingTop{
  top: -40px;
  position: absolute;
  left: 15px;
  width: calc(100% - 30px);
  border-radius: 12px !important;
}
@media (min-width: 768px){
  .floatingTop{
    left: 10px;
    width: calc(100% - 20px);
  }
}
img.appyloan_img{ bottom: 20px !important ;}
.step-wrap {
    gap: 5px;
    text-align: center;
    font-size: .9em;
    color: #878787;;
}
.step-wrap .stepIndicator {
    position: relative;
    flex: 1;
    padding-top: 40px;
}
.stepIndicator span{
  position: absolute;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 6px;
  color: #A7A9AC;;
  z-index: 10;
  font-weight: 500;
}
.step-wrap .stepIndicator.active {
    font-weight: 500;
    color: #04da8c;
}
.step-wrap .stepIndicator.active.rejected {
    font-weight: 500;
    color: #e74c3c;
}
.step-wrap .stepIndicator.active span {
  color: #fff;
  font-weight: 600;
}
.step-wrap .stepIndicator.finish {
    font-weight: 600;
    color: #04da8c;
}
.step-wrap .stepIndicator::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: 9;
    width: 30px;
    height: 30px;
    background-color:#fff;
    border-radius: 50%;
    border: 3px solid #A7A9AC;
}
.step-wrap .stepIndicator.active::before {
    background-color: #04da8c;
    border: 3px solid #04da8c;
}
.step-wrap .stepIndicator.active.rejected::before {
    background-color: #e74c3c;
    border: 3px solid #e74c3c;
}

.step-wrap .stepIndicator.active.rejected::after {
    background-color:#e74c3c;
}
.step-wrap .stepIndicator.finish::before {
    background-color: #04da8c;
    border: 3px solid #A7A9AC;
}
.step-wrap .stepIndicator:first-child::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 12px;
    width: 58%;
    height: 3px;
    background-color: #A7A9AC;;
}
.step-wrap .stepIndicator::after {
    content: "";
    position: absolute;
    left: 0%;
    top: 12px;
    width: 110%;
    height: 3px;
    background-color: #A7A9AC;;
}

.step-wrap .stepIndicator.active::after {
    background-color: #04da8c;
}
.step-wrap .stepIndicator:last-child:after {
    width: 50%;
}
</style>